import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { backend_uri } from "../../util/constants";

class MembershipForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breweries: [],
      title: "",
      brewery: "",
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch(backend_uri + "/breweries")
      .then((response) => response.json())
      .then((data) => {
        let breweries = [];
        for (let key in data["breweries"]) {
          breweries.push(data["breweries"][key]);
        }
        this.setState({ breweries });
      });
  }

  handleChange(event) {
    console.log(event.target.name + " changed");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleBreweryChange(event) {
    console.log(event.target.value);
    this.setState({ brewery: event.target.value });
  }

  isDisabled() {
    return this.state.title === "" || this.state.brewery === "";
  }

  handleSubmit(event) {
    event.preventDefault();
    const program = {
      title: this.state.title,
      brewery: this.state.brewery,
    };
    const { getAccessTokenSilently } = this.props.auth0;
    this.setState({ isLoading: true });
    getAccessTokenSilently()
      .then((token) => {
        console.log("token fetch success: ", token);
        this.setState({ token });
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          mode: "cors",
          body: JSON.stringify(program),
        };
        let url = backend_uri + "/membership_programs";
        console.log(url, requestOptions);
        fetch(url, requestOptions)
          .then((response) => {
            if (response.status >= 400) {
              console.log("Bad status: ", response.status);
              this.setState({
                err: { message: response.status + ": " + response.statusText },
                isLoading: false,
              });
              throw new Error(
                "Bad response: (" + response.status + ") " + response.statusText
              );
            }
            this.setState({ isLoading: false, brewery: "", title: "" });
            this.props.refresh();
          })
          .catch((err) => {
            console.log("Error posting new program: ", err);
          });
      })
      .catch((err) => {
        let message = "Error fetching token: " + err.message;
        alert(message);
        console.log(message);
      });
  }

  render() {
    return (
      <div className="Form">
        <h3>Add a membership program:</h3>{" "}
        <a onClick={this.props.close}>Close</a>
        <form onSubmit={this.handleSubmit}>
          <div className="FormRow">
            Name:
            <input
              type="text"
              name="title"
              value={this.state.title}
              onChange={this.handleChange}
            />
          </div>
          <div className="FormRow">
            Brewery:
            <select
              value={this.state.brewery}
              onChange={this.handleChange}
              name="brewery"
            >
              <option value="">---</option>
              {this.state.breweries.map((brewery) =>
                this.props.ownerships.includes(brewery.key) ? (
                  <option value={brewery.key} key={brewery.key}>
                    {brewery.name}
                  </option>
                ) : null
              )}
            </select>
          </div>
          <div className="FormRow">
            <input type="submit" name="Submit" disabled={this.isDisabled()} />
          </div>
        </form>
      </div>
    );
  }
}
export default withAuth0(MembershipForm);
