import { logo_url } from "../../util/constants";
import React from "react";

export default class Title extends React.Component {
  render() {
    return (
      <a href="/" className="Title-link">
        <div className="Title">
          <div className="Title-logo">
            <img src={logo_url} />
          </div>
          <div className="Title-word">
            <h1>Jeu·dre·di</h1>
            <h2>
              /ʒø.dʁə.di/ <i>noun</i>
            </h2>
          </div>
          <div className="Title-definition">
            <p>1. A portmanteau of Jeudi (Thursday) and Vendredi (Friday)</p>
            <p>2. Time for a beer</p>
          </div>
        </div>
      </a>
    );
  }
}
