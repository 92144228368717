import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

function Wrapper({ children }) {
  const { isLoading, user, error, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  return (
    <>
      {isAuthenticated ? <div>Hello {user.name}</div> : null}
      {children}
    </>
  );
}

export default Wrapper;
