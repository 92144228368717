import React from "react";
import { backend_uri } from "../../util/constants";
import { withAuth0 } from "@auth0/auth0-react";

class BeerMicro extends React.Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  delete() {
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently()
      .then((token) => {
        fetch(backend_uri + "/beverages/" + this.props.beer.id, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        })
          .catch((err) => {
            console.log("Deleteing " + this.props.beer.id + " failed: " + err);
          })
          .then((resp) => {
            console.log(
              this.props.beer.id + " deleted successfully: " + resp.status
            );
            this.props.refresh();
          });
      })
      .catch((err) => {
        console.log("Failed to get token: " + err);
      });
  }

  render() {
    let beer = this.props.beer;
    return (
      <li className="BeerMicro">
        <a href={"/beers/" + beer.id}>{beer.name}</a> | <i>{beer.style}</i>{" "}
        {beer.abv != null ? "| " + beer.abv + "%" : null} |{" "}
        <b>{beer.brewery.name}</b>
        {this.props.canDelete ? (
          <a onClick={this.delete} className="DeleteButton">
            X
          </a>
        ) : null}
      </li>
    );
  }
}

export default withAuth0(BeerMicro);
