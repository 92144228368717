import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { api_audience, backend_uri } from "../util/constants";
import BeerMicro from "../components/Beers/BeerMicro";
import BeerForm from "../components/Beers/BeerForm";

class Beers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beers: [],
      isLoading: true,
      canPost: false,
      ownerships: [],
      canDelete: false,
    };
    this.refreshList();
    this.refreshList = this.refreshList.bind(this);
  }

  refreshList() {
    this.setState({ isLoading: true });
    this.doTheFetch().catch((err) => {
      console.log("Fetch failed: ", err);
      this.setState({ err, isLoading: false });
    });
  }

  async doTheFetch() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.setState({ isLoading: false });
    getAccessTokenSilently({
      audience: api_audience,
    })
      .then((token) => {
        console.log("token fetch success: ", token);
        this.setState({ token });
        fetch(backend_uri + "/beverages", {
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
          console.log("resp: ", response);
          if (response.status !== 200) {
            console.log("Bad status: ", response.status);
            this.setState({
              err: { message: response.status + ": " + response.statusText },
              isLoading: false,
            });
            throw new Error(
              "Bad response: (" + response.status + ") " + response.statusText
            );
          }
          response
            .json()
            .then((data) => {
              console.log("data: ", data);
              let beers = [];
              data["beers"].forEach((beer) => beers.push(beer));
              this.setState({ beers });
              console.log(beers);
            })
            .catch((err) => {
              console.log("Beer list fetch failed: ", err);
              this.setState({ err, isLoading: false });
            })
            .finally(() => this.setState({ ...this.state, isLoading: false }));
        });
        fetch(backend_uri + "/permissions/delete:beverages", {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            if ("hasPermission" in data) {
              this.setState({ canDelete: data["hasPermission"] });
            }
          })
          .catch((err) => {
            console.log("Error checking delete permission: ", err);
          });
        fetch(backend_uri + "/permissions/create:beverages", {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            if ("hasPermission" in data) {
              this.setState({ canPost: data["hasPermission"] });
            }
          })
          .catch((err) => {
            console.log("Error checking create permission: ", err);
          });
        fetch(backend_uri + "/ownerships", {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            let ownerships = [];
            data["ownerships"].forEach((ownership) => {
              ownerships.push(ownership["brewery"]["key"]);
            });
            console.log("Ownerships: ", ownerships);
            this.setState({ ownerships });
          })
          .catch((err) => {
            console.log("Error fetching ownerships: ", err);
          });
      })
      .catch((err) => {
        console.log("getToken failed: ", err);
        this.setState({ err, isLoading: false });
      });
  }

  render() {
    return (
      <div>
        <div className="Beers">
          <h3>
            Beers: <a onClick={this.refreshList}>Refresh</a>
          </h3>
          {this.state.isLoading ? (
            <div>
              <p>Loading...</p>
            </div>
          ) : this.state.err != null ? (
            <div>
              <p>{this.state.err.message}</p>
            </div>
          ) : (
            <ul className="Beers-list">
              {this.state.beers.map((beer) => (
                <BeerMicro
                  beer={beer}
                  key={beer.id}
                  refresh={this.refreshList}
                  canDelete={
                    this.state.canDelete &&
                    this.state.ownerships.includes(beer.brewery.key)
                  }
                />
              ))}
            </ul>
          )}
        </div>
        {this.state.canPost ? (
          <BeerForm
            refresh={this.refreshList}
            ownerships={this.state.ownerships}
          />
        ) : null}
      </div>
    );
  }
}

export default withAuth0(Beers);
