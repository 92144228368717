import { Component } from "react";

class Home extends Component {
  render() {
    return (
      <div>
        <p>This is where home goes...</p>
      </div>
    );
  }
}

export default Home;
