import "../../styles/App.css";
import React from "react";
import { Outlet } from "react-router-dom";
import Title from "./Title";
import NavBar from "./NavBar";

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Title />
        <NavBar />
        <Outlet />
      </div>
    );
  }
}
