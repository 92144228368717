import React from "react";
import { render } from "react-dom";
import "./styles/index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { audience, clientId, domain, scope } from "./util/config";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Beers from "./pages/Beers";
import Home from "./pages/Home";
import MembershipViewer from "./pages/MembershipsViewer";
import MembershipDetails from "./pages/MembershipDetails";
import Wrapper from "./components/App/Wrapper";
import AuthProvider from "./components/App/AuthProvider";

const rootElement = document.getElementById("root");

render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<Home />} />
            <Route
              path="beers"
              element={
                <Wrapper>
                  <Beers />
                </Wrapper>
              }
            />
            <Route
              path="my_memberships"
              element={
                <Wrapper>
                  <MembershipViewer />
                </Wrapper>
              }
            />
            <Route
              path="memberships/:programId"
              element={
                <Wrapper>
                  <MembershipDetails />
                </Wrapper>
              }
            />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
