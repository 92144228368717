import BeerMicro from "../components/Beers/BeerMicro";
import { backend_uri } from "../util/constants";
import React from "react";
// import { withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import BeerForm from "../components/Beers/BeerForm";

class MembershipDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      program: {},
      beers: [],
      isLoading: true,
      err: null,
      canDelete: false,
      canPost: false,
      ownership: false,
      add: false,
    };
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const programId = this.props.params.programId;
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently()
      .then((token) => {
        console.log("Token fetched successfully: ", token);
        fetch(backend_uri + "/membership_programs/" + programId, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({ program: data.program });
            fetch(
              backend_uri + "/membership_programs/" + programId + "/beverages",
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                let beers = [];
                console.log(data);
                data["beverages"].forEach((beer) => beers.push(beer));
                console.log(beers);
                this.setState({ beers, isLoading: false });
              })
              .catch((err) => {
                this.setState({ err, isLoading: false });
              });
            fetch(backend_uri + "/ownerships/" + data.program.brewery.id, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((response) => {
                if (response.status < 400) {
                  this.setState({ ownership: true });
                }
              })
              .catch((err) => {
                console.log("Error fetching ownerships: ", err);
              });
          })
          .catch((err) => {
            this.setState({ err, isLoading: false });
          });
        fetch(backend_uri + "/permissions/delete:beverages", {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            if ("hasPermission" in data) {
              this.setState({ canDelete: data["hasPermission"] });
            }
          })
          .catch((err) => {
            console.log("Error checking delete permission: ", err);
          });

        fetch(backend_uri + "/permissions/create:beverages", {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => {
            if ("hasPermission" in data) {
              this.setState({ canPost: data["hasPermission"] });
            }
          })
          .catch((err) => {
            console.log("Error checking create permission: ", err);
          });
      })
      .catch((err) => {
        console.log("Error fetching token: ", err);
        this.setState({ err, isLoading: false });
      });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div>
            <p>Loading...</p>
          </div>
        ) : this.state.err != null ? (
          <div>
            <p>{this.state.err.message}</p>
          </div>
        ) : (
          <div className="Beers">
            <h3>
              {this.state.program.title}{" "}
              {this.state.program.brewery.name != null
                ? "from " + this.state.program.brewery.name
                : null}{" "}
              <a onClick={this.refresh}>Refresh</a>{" "}
              {this.state.canPost && this.state.ownership ? (
                <a onClick={() => this.setState({ add: true })}>Create</a>
              ) : null}
            </h3>
            <ul className="Beers-list">
              {this.state.beers.map((beer) => (
                <BeerMicro
                  beer={beer}
                  key={beer.id}
                  refresh={this.refreshList}
                  canDelete={this.state.canDelete && this.state.ownership}
                />
              ))}
            </ul>
          </div>
        )}
        {this.state.add && this.state.canPost && this.state.ownership ? (
          <BeerForm refresh={this.refresh} program={this.state.program} />
        ) : null}
      </div>
    );
  }
}

export default withAuth0((props) => (
  <MembershipDetails {...props} params={useParams()} />
));
