import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { api_audience, backend_uri } from "../../util/constants";

class BeerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brewery: "",
      name: "",
      style: "",
      abv: "",
      isLoading: false,
      err: null,
      breweries: [],
      programs: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.program) {
      this.setState({
        brewery: this.props.program.brewery.key,
        programs: [this.props.program.key],
      });
    }
    fetch(backend_uri + "/breweries")
      .then((response) => response.json())
      .then((data) => {
        let breweries = [];
        for (let key in data["breweries"]) {
          breweries.push(data["breweries"][key]);
        }
        this.setState({ breweries });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  isDisabled() {
    return (
      this.state.brewery === "" ||
      this.state.name === "" ||
      this.state.style === "" ||
      this.state.abv === ""
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("Submitting!");
    const abv = parseFloat(this.state.abv);
    if (isNaN(abv)) {
      console.log("Abv was NaN: ", abv, " ", this.state.abv);
    } else {
      this.postBeer({
        name: this.state.name,
        brewery: this.state.brewery,
        style: this.state.style,
        memberships: this.state.programs,
        abv: abv,
      })
        .then(() => {
          this.setState({
            brewery: "",
            name: "",
            style: "",
            abv: "",
          });
          this.props.refresh();
        })
        .catch((err) => {
          console.log("Submit failed: ", err);
          // do something
        });
    }
  }

  async postBeer(beer) {
    const { getAccessTokenSilently } = this.props.auth0;
    this.setState({ isLoading: true });
    getAccessTokenSilently({
      audience: api_audience,
    })
      .then((token) => {
        console.log("token fetch success: ", token);
        this.setState({ token });
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          mode: "cors",
          body: JSON.stringify(beer),
        };
        let url = backend_uri + "/beverages";
        console.log(url, requestOptions);
        fetch(url, requestOptions)
          .then((response) => {
            if (response.status >= 400) {
              console.log("Bad status: ", response.status);
              this.setState({
                err: { message: response.status + ": " + response.statusText },
                isLoading: false,
              });
              throw new Error(
                "Bad response: (" + response.status + ") " + response.statusText
              );
            }
            this.setState({ isLoading: false });
          })
          .catch((err) => {
            console.log("Failed here: ", err);
          });
      })
      .catch((err) => {
        console.log("getToken failed: ", err);
        this.setState({ err, isLoading: false });
      });
  }

  render() {
    console.log("Breweries: ", this.state.breweries);
    return (
      <div className="Form">
        <h3>Add a beer:</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="FormRow">
            Name:
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="FormRow">
            Style:
            <input
              type="text"
              name="style"
              value={this.state.style}
              onChange={this.handleChange}
            />
          </div>
          <div className="FormRow">
            ABV:
            <input
              type="number"
              name="abv"
              step="0.1"
              value={this.state.abv}
              onChange={this.handleChange}
            />
          </div>
          {!this.props.program ? (
            <div className="FormRow">
              Brewery:
              <select
                value={this.state.brewery}
                onChange={this.handleChange}
                name="brewery"
              >
                <option value="">---</option>
                {this.state.breweries.map((brewery) =>
                  this.props.ownerships.includes(brewery.key) ? (
                    <option value={brewery.key} key={brewery.key}>
                      {brewery.name}
                    </option>
                  ) : null
                )}
              </select>
            </div>
          ) : null}
          <div className="FormRow">
            <input type="submit" name="Submit" disabled={this.isDisabled()} />
          </div>
        </form>
      </div>
    );
  }
}

export default withAuth0(BeerForm);
