import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { backend_uri } from "../../util/constants";

class MembershipsExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      isLoading: false,
      userRecords: [],
      canDelete: false,
      ownerships: [],
    };
    this.fetchList = this.fetchList.bind(this);
    this.fetchMembershipRecords = this.fetchMembershipRecords.bind(this);
    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.fetchList();
    this.fetchMembershipRecords();
  }

  fetchList() {
    this.setState({ isLoading: true });
    fetch(backend_uri + "/membership_programs")
      .then((response) => response.json())
      .then((data) => {
        let programs = [];
        for (let key in data["programs"]) {
          programs.push(data["programs"][key]);
        }
        this.setState({ programs, isLoading: false });
      })
      .catch((err) => {
        this.setState({ err, isLoading: false });
      });
  }

  fetchMembershipRecords() {
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently().then((token) => {
      console.log("token fetch success: ", token);
      fetch(backend_uri + "/membership_records", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Records: ", data);
          let userRecords = [];
          for (let record in data["records"]) {
            // console.log("Record: ", data["records"][record]);
            userRecords.push(data["records"][record]["program"]["key"]);
          }
          console.log(userRecords);
          this.setState({ userRecords });
        })
        .catch((err) => {
          this.setState({ err });
        });
      fetch(backend_uri + "/permissions/delete:beverages", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if ("hasPermission" in data) {
            this.setState({ canDelete: data["hasPermission"] });
          }
        })
        .catch((err) => {
          console.log("Error checking delete permission: ", err);
        });
      fetch(backend_uri + "/ownerships", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          let ownerships = [];
          data["ownerships"].forEach((ownership) => {
            ownerships.push(ownership["brewery"]["key"]);
          });
          console.log("Ownerships: ", ownerships);
          this.setState({ ownerships });
        })
        .catch((err) => {
          console.log("Error fetching ownerships: ", err);
        });
    });
  }

  refresh() {
    this.fetchList();
    this.fetchMembershipRecords();
  }

  delete(program) {
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently()
      .then((token) => {
        fetch(backend_uri + "/membership_programs/" + program.id, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        })
          .catch((err) => {
            console.log("Deleting " + program.id + " failed: " + err);
          })
          .then((resp) => {
            console.log(program.id + " deleted successfully: " + resp.status);
            this.fetchList();
          });
      })
      .catch((err) => {
        console.log("Failed to get token: " + err);
      });
  }

  handleSubscriptionChange(event, id) {
    const sub = event.target.checked;
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently()
      .then((token) => {
        let requestOptions = {
          headers: { Authorization: `Bearer ${token}` },
        };
        let method;
        if (sub) {
          method = "POST";
        } else {
          method = "DELETE";
        }
        requestOptions["method"] = method;
        fetch(backend_uri + "/membership_records/" + id, requestOptions)
          .then((data) => {
            console.log(method + " of " + id + " record succeeded");
            this.fetchMembershipRecords();
          })
          .catch((err) => {
            console.log(method + " of " + id + " record failed: " + err);
          });
      })
      .catch((err) => console.log("Failed to get token: " + err));
  }

  render() {
    console.log("Programs:", this.state.programs);
    return (
      <div>
        <h3>
          All Programs: <a onClick={this.refresh}>Refresh</a>{" "}
          <a onClick={this.props.close}>Close</a>
        </h3>
        {this.state.isLoading ? (
          <div>
            <p>Loading...</p>
          </div>
        ) : this.state.err != null ? (
          <div>
            <p>{this.state.err.message}</p>
          </div>
        ) : (
          <ul className="Beers-list">
            {this.state.programs.map((program) => (
              <li key={program.key}>
                {program.title} | {program.brewery.name}{" "}
                {this.state.canDelete &&
                this.state.ownerships.includes(program.brewery.key) ? (
                  <a
                    onClick={() => this.delete(program)}
                    className="DeleteButton"
                  >
                    X
                  </a>
                ) : null}
                <input
                  type="checkbox"
                  checked={this.state.userRecords.includes(program.key)}
                  name="subscribed"
                  onChange={(event) =>
                    this.handleSubscriptionChange(event, program.id)
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withAuth0(MembershipsExplorer);
