import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { backend_uri } from "../util/constants";
import MembershipForm from "../components/Memberships/MembershipForm";
import MembershipsExplorer from "../components/Memberships/MembershipsExplorer";

class MembershipsViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      isLoading: false,
      userRecords: [],
      explore: false,
      add: false,
      canPost: false,
      ownerships: [],
      canDelete: false,
    };
    this.fetchMembershipRecords = this.fetchMembershipRecords.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.fetchMembershipRecords();
  }

  fetchMembershipRecords() {
    const { getAccessTokenSilently } = this.props.auth0;
    getAccessTokenSilently().then((token) => {
      console.log("token fetch success: ", token);
      fetch(backend_uri + "/membership_records", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Records: ", data);
          let userRecords = [];
          for (let record in data["records"]) {
            // console.log("Record: ", data["records"][record]);
            userRecords.push(data["records"][record]["program"]);
          }
          console.log(userRecords);
          this.setState({ userRecords });
        })
        .catch((err) => {
          this.setState({ err });
        });

      fetch(backend_uri + "/permissions/delete:beverages", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if ("hasPermission" in data) {
            this.setState({ canDelete: data["hasPermission"] });
          }
        })
        .catch((err) => {
          console.log("Error checking delete permission: ", err);
        });
      fetch(backend_uri + "/permissions/create:beverages", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if ("hasPermission" in data) {
            this.setState({ canPost: data["hasPermission"] });
          }
        })
        .catch((err) => {
          console.log("Error checking create permission: ", err);
        });
      fetch(backend_uri + "/ownerships", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          let ownerships = [];
          data["ownerships"].forEach((ownership) => {
            ownerships.push(ownership["brewery"]["key"]);
          });
          console.log("Ownerships: ", ownerships);
          this.setState({ ownerships });
        })
        .catch((err) => {
          console.log("Error fetching ownerships: ", err);
        });
    });
  }

  refresh() {
    this.fetchMembershipRecords();
  }

  render() {
    console.log("Programs:", this.state.programs);
    return (
      <div>
        <div>
          <h3>
            Your Memberships: <a onClick={this.refresh}>Refresh</a>{" "}
            {this.state.canPost ? (
              <a onClick={() => this.setState({ add: true })}>Create</a>
            ) : null}{" "}
            <a onClick={() => this.setState({ explore: true })}>Explore</a>
          </h3>
          {this.state.isLoading ? (
            <div>
              <p>Loading...</p>
            </div>
          ) : this.state.err != null ? (
            <div>
              <p>{this.state.err.message}</p>
            </div>
          ) : (
            <ul className="Beers-list">
              {this.state.userRecords.map((program) => (
                <li key={program.key}>
                  <a href={"/memberships/" + program.id}>{program.title}</a> |{" "}
                  {program.brewery.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        {this.state.add ? (
          <MembershipForm
            refresh={this.fetchList}
            close={() => this.setState({ add: false })}
            ownerships={this.state.ownerships}
          />
        ) : null}
        {this.state.explore ? (
          <MembershipsExplorer
            close={() => this.setState({ explore: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default withAuth0(MembershipsViewer);
