import React from "react";
import { withAuth0 } from "@auth0/auth0-react";

class NavBar extends React.Component {
  render() {
    const { isAuthenticated, logout, loginWithRedirect } = this.props.auth0;
    const menu_items = [
      { name: "Web Shop", uri: "/beers", onClick: null },
      { name: "My Memberships", uri: "/my_memberships", onClick: null },
      isAuthenticated
        ? {
            name: "Logout",
            uri: null,
            onClick: () => {
              logout({ returnTo: window.location.origin });
            },
          }
        : {
            name: "Login/Register",
            uri: null,
            onClick: loginWithRedirect,
          },
      { name: "Contact & Help", uri: "/help", onClick: null },
      isAuthenticated ? { name: "My Account", uri: "/account" } : null,
    ];
    return (
      <div className="Nav-menu">
        <ul className="Nav-list">
          {menu_items.map((item) =>
            item ? (
              <li key={item.name}>
                <a href={item.uri} onClick={item.onClick}>
                  {item.name}
                </a>
              </li>
            ) : null
          )}
        </ul>
      </div>
    );
  }
}

export default withAuth0(NavBar);
